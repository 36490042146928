import React from "react"

import Layout from "../components/layout"

const NotFoundPageLv = ({pageContext}) => (
  <Layout language={pageContext.language}>
    <div className={"page-content"}>
      <div className={"container"}>
        <div className="content-wrapper">
          <div className="content">
            <h1>KĻŪDA 404. LAPA NAV ATRASTA</h1>
            <p>Lapa, kuru meklējat nav atrodama.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPageLv
